import React from 'react';
import { NavLink } from 'react-router-dom';

const NavigationSide = () => {
    return (
        <nav className="side-nav">
            <div className="nav-menu">
                <div className="menu">
                    <ul>
                        <li>
                            <NavLink
                                to="/dashboard"
                                activeClassName="active"
                            >
                                Dashboard
                            </NavLink>
                        </li>
                    </ul>

                    { _NAV_MENUS.map((item, idx) => {
                        return (
                            < >
                                <h3 key={ 'awdaw' + idx }>{ item.title }</h3>
                                <ul key={ 'awdaw33' + idx }>
                                    { item.menus.map((men, idx2) => {
                                        return (
                                            <li
                                                key={ idx2 + 'zed414sd' + idx }
                                            >
                                                <NavLink
                                                    key={ idx2 + 'z11123ed414sd' + idx }
                                                    to={ men.link }
                                                    activeClassName="active"
                                                >
                                                    { men.name }
                                                </NavLink>
                                            </li>
                                        );
                                    }) }
                                </ul>
                            </>
                        );
                    }) }
                </div>
            </div>
        </nav>
    );
};

export default NavigationSide;

const _NAV_MENUS = [
    {
        title: 'Bots',
        menus: [
            {
                name: 'Jobs',
                link: '/bots/jobs'
            },
            {
                name: 'Messages',
                link: '/bots/messages'
            },
            {
                name: 'Logs',
                link: '/bots/logs'
            },
            {
                name: 'Categories and Types',
                link: '/bots/cat-types'
            },
            // {
            //     name: 'Statistics',
            //     link: '/bots/stats'
            // },
            {
                name: 'Bots upload',
                link: '/bots-upload/list'
            },
            {
                name: 'Send message',
                link: '/bots/send-message'
            }
        ]
    },
    {
        title: 'SyAgent',
        menus: [
            {
                name: 'Users',
                link: '/syagent/users'
            },
            {
                name: 'Servers',
                link: '/syagent/servers'
            }, {
                name: 'Sent Alerts',
                link: '/syagent/sentAlerts'
            }
        ]
    },
    // {
    //     title: 'Juicy Elf',
    //     menus: [
    //         {
    //             name: 'Users',
    //             link: '/juice/users'
    //         },
    //         {
    //             name: 'Comments',
    //             link: '/juice/comments'
    //         }
    //     ]
    // },
    {
        title: 'Tele AD',
        menus: [
            {
                name: 'Media',
                link: '/telegram-adult/list'
            },
            {
                name: 'MComments',
                link: '/telegram-adult/comments'
            },
            {
                name: 'All medias',
                link: '/telegram-adult/medias'
            }
        ]
    },
    {
        title: 'Text me',
        menus: [
            {
                name: 'Users',
                link: '/textme/users'
            },
            {
                name: 'Comments',
                link: '/textme/comments'
            }
        ]
    },
    {
        title: 'No Shit',
        menus: [
            {
                name: 'Users',
                link: '/noshit/users'
            },
            {
                name: 'Comments',
                link: '/noshit/comments'
            }
        ]
    }
];
