import { action, observable } from "mobx";
import fetchService from "../services/fetch.service";

class TelegramAdultStore {
    @observable list = [];
    @observable medias = [];
    @observable comments = [];

    @action.bound
    async getList() {
        if (!this.list.length) {
            const list = await fetchService.authenticatedGet("/huj/telegram-adult/get");
            this.list = list.data;
        }
    }

    @action.bound
    async getMedias() {
        if (!this.list.length) {
            const list = await fetchService.authenticatedGet("/huj/telegram-adult/medias");
            this.medias = list.data;
        }
    }

    @action.bound
    async getComments() {
        const comments = await fetchService.authenticatedGet("/huj/telegram-adult/comments");
        this.comments = comments.data;
    }

    @action.bound
    async edit(id, action, meta) {
        const data = await fetchService.authenticatedPost("/huj/telegram-adult/edit", { _id: id, status: action, meta });
        this.medias = this.medias.filter(item => item._id !== id);
        const copyOfMedias = [...this.medias];
        copyOfMedias.push(data.data);
        this.medias = copyOfMedias;
    }
}

const telegramAdultStore = new TelegramAdultStore();

export default telegramAdultStore;
