import React, { Component } from "react";
import { observer } from "mobx-react";
import messagesStore from "../../../../stores/messages.store";
import NavigationSide from "../../../components/navigation-side/NavigationSide";
import NavigationTop from "../../../components/navigation-top/NavigationTop";
import LoaderComponent from "../../../components/loader/Loader.component";
import { Styles } from "./styled";

@observer
class MessagesEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            name: "",
            body: "",
            promoLink: "",
            promoText: "",
            imageUrl: "",
            items: []
        };
    }

    handleInputChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        });
    };

    handleInputChangeItems = (e, type, idx) => {
        const { items } = this.state;
        items[idx][type] = e.target.value;
        this.setState({
            items
        });
    };

    handleAddItems = () => {
        const { items } = this.state;
        items.push({
            promoLink: "",
            promoText: "",
            imageUrl: ""
        });
        this.setState({ items });
    };

    handleRemoveItems = (idx) => {
        const { items } = this.state;
        items.splice(idx, 1);
        this.setState({ items });
    };

    handleSave = async e => {
        e.preventDefault();
        this.setState({ loader: true });
        const { name, body, promoText, promoLink, imageUrl, items } = this.state;
        const { id } = this.props.match.params;
        const payload = {
            body: body,
            name: name,
            promoLink: promoLink,
            promoText: promoText,
            imageUrl,
            items
        };
        if (id) {
            await messagesStore.updateMessage(id, payload);
        } else {
            await messagesStore.createMessage(payload);
        }
        this.props.history.push("/bots/messages");
    };

    async componentDidMount() {
        document.title = "Message edit - shemesh.info";
        const { id } = this.props.match.params;

        if (id) {
            const message = await messagesStore.getMessage(id);
            if (message) {
                const plink = message.promoLink ? message.promoLink : "";
                const ptext = message.promoText ? message.promoText : "";
                this.setState(
                    { name: message.name, body: message.body, loader: false, promoLink: plink, promoText: ptext, imageUrl: message.imageUrl || "", items: message.items || [] });
            }
        } else {
            this.setState({ loader: false });
        }
    }

    render() {
        const { loader, name, body, promoLink, promoText, imageUrl, items } = this.state;
        const { id } = this.props.match.params;

        console.log("items", items);

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { loader && (<LoaderComponent/>) }

                    { !loader && (
                        <Styles className="message-edit">
                            <h1>{ id ? "Edit Message" : "Add New Message" }</h1>

                            <div className="form-item">
                                <label>
                                    Name
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        "name"
                                    ) }
                                    value={ name }
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <label>
                                    Message Body
                                </label>
                                <textarea
                                    onChange={ (e) => this.handleInputChange(e,
                                        "body"
                                    ) }
                                    value={ body }
                                />
                            </div>

                            <div className="form-item">
                                <label>
                                    promoText
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        "promoText"
                                    ) }
                                    value={ promoText }
                                    type="text"/>
                            </div>
                            <div className="form-item">
                                <label>
                                    promoLink
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        "promoLink"
                                    ) }
                                    value={ promoLink }
                                    type="text"/>
                            </div>
                            <div className="form-item">
                                <label>
                                    image url
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        "imageUrl"
                                    ) }
                                    value={ imageUrl }
                                    type="text"/>
                            </div>

                            <h4>Items:</h4>
                            { items.map((item, index) => {
                                return (
                                    <div key={ index }>
                                        <div className="form-item">
                                            <label>
                                                promoText
                                            </label>
                                            <input
                                                onChange={ (e) => this.handleInputChangeItems(e,
                                                    "promoText", index
                                                ) }
                                                value={ item.promoText }
                                                type="text"/>
                                        </div>
                                        <div className="form-item">
                                            <label>
                                                promoLink
                                            </label>
                                            <input
                                                onChange={ (e) => this.handleInputChangeItems(e,
                                                    "promoLink", index
                                                ) }
                                                value={ item.promoLink }
                                                type="text"/>
                                        </div>
                                        <div className="form-item">
                                            <label>
                                                image url
                                            </label>
                                            <input
                                                onChange={ (e) => this.handleInputChangeItems(e,
                                                    "imageUrl", index
                                                ) }
                                                value={ item.imageUrl }
                                                type="text"/>
                                        </div>

                                        <button
                                            onClick={ this.handleRemoveItems }
                                            className="button dang">
                                            Remove
                                        </button>
                                    </div>
                                );
                            }) }

                            <div className="form-item">
                                <button
                                    onClick={ this.handleAddItems }
                                    style={ { margin: "10px" } }
                                    className="button info">
                                    Add Items
                                </button>
                            </div>

                            <div className="form-item">
                                <button
                                    onClick={ this.handleSave }
                                    className="button suc">
                                    Save
                                </button>
                            </div>
                        </Styles>
                    ) }

                </main>
            </div>
        );
    }
}

export default MessagesEditView;
