import React from "react";
import styled from "styled-components";
import telegramAdultStore from "../../../stores/telegramAdult.store";

export const Styles = styled.div`
    img {
        width: 400px;
    }
`;
const TelegramAdultItem = ({ item }) => {
    return (
        <Styles>
            <div className="name"><b>{ item.name }</b></div>
            <div className="name">{ item.category }</div>
            <div className="name"><a href={ item.link } target="_blank">{ item.link }</a></div>

            <img src={ item.thumb } alt=""/>
            <button
                onClick={ () => telegramAdultStore.edit(item._id, "APPROVED", { picture: true }) }
                className="button suc">Approve
            </button>
            <button
                onClick={ () => telegramAdultStore.edit(item._id, "REJECTED") }
                className="button dang">Reject
            </button>
            <button
                onClick={ () => telegramAdultStore.edit(item._id, "REJECTED", true) }
                className="button dang">BAN
            </button>
        </Styles>
    );
};

export default TelegramAdultItem;
