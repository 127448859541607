import React from "react";
import styled from "styled-components";
import telegramAdultStore from "../../../stores/telegramAdult.store";

export const Styles = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border: 1px solid;
    padding: 5px;
    margin: 5px;

    img {
        width: 400px;
    }
`;
const TelegramAdultItemMedia = ({ item }) => {
    const isSticky = item.sticky;
    const buttonText = isSticky ? "Unsticky" : "Make Sticky";

    return (
        <Styles>
            <div className="info">
                <div className="name"><b>{ item.name }</b></div>
                <div className="name"><b>{ item.status }</b></div>
                <div className="name">{ item.category }</div>
                <div className="name">{ item.membersCount }</div>
                <div className="name"><a href={ item.link } target="_blank">{ item.link }</a></div>
                <div className="name">{isSticky ? "STICKY": ""}</div>
            </div>
            <div className="actions">
                <button
                    onClick={ () => telegramAdultStore.edit(item._id, "APPROVED", { sticky: !isSticky }) }
                    className="button suc">{ buttonText }
                </button>
            </div>
        </Styles>
    );
};

export default TelegramAdultItemMedia;
