import React, {Component} from 'react';
import styled from "styled-components";
import TelegramAdultItem from "./TelegramAdultItem";
import NavigationSide from "../../components/navigation-side/NavigationSide";
import NavigationTop from "../../components/navigation-top/NavigationTop";
import {observer} from "mobx-react";
import LoaderComponent from "../../components/loader/Loader.component";
import telegramAdultStore from "../../../stores/telegramAdult.store";
import TelegramAdultItemMedia from "./TelegramAdultItemMedia";

export const Styles = styled.div`
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;;

  @media (max-width: 900px) {
    display: block;
  }
`;

@observer
class TelegramAdultMediasView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'T A Medias - Shit';
        await telegramAdultStore.getMedias();
        this.setState({loader: false});
    }

    render() {
        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }
                    { !this.state.loader && (
                        <Styles>
                            {telegramAdultStore.medias.map(item => {
                                return (
                                    <TelegramAdultItemMedia
                                        key={item._id}
                                        item={item}
                                    />
                                );
                            })}
                        </Styles>
                    )}
                </main>
            </div>)
    }
}

export default TelegramAdultMediasView;
